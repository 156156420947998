import React from "react";
import { Link } from "react-scroll";
import "../css/navigationBar.css";

const NavigationBar = () => (
  <>
    <div className="navbar-container container-lg">
      <nav className="navbar">
        <Link
          activeClass="active"
          to="projects"
          spy
          smooth
          offset={-70}
          duration={500}
        >
          Projects
        </Link>
        <a
          href="https://www.canmert.blog"
          rel="noreferrer"
          target="_blank"
          to="canmert.blog"
        >
          Blog
        </a>
        <Link
          activeClass="active"
          to="contact"
          spy
          smooth
          offset={-70}
          duration={500}
        >
          Contact
        </Link>

      </nav>
    </div>
  </>
);

export default NavigationBar;
