import coffeeTimeImage from "../assets/images/1024.png";
import autoBookImage from "../assets/images/autobook.png";

export default [

  {
    title: "Coffee Time",
    description:
        "Coffee Time shows all the coffee machines around based on user's location. Currently only available in Varna. Available on iOS and Android",
    skills: ["ReactJS", "React Native", "NodeJS", "Express", "MongoDB"],
    image: coffeeTimeImage,
    links: {
      github: "https://github.com/caanmert/coffeeTime-mobile",
      preview: "https://play.google.com/store/apps/details?id=com.coffeetime",
    },
  },

  {
    title: "Coffee Time Admin",
    description:
        "Admin panel where only specific users can login and add the coffee machines by sharing their location.They can make CRUD operations and review newly added machines to ensure if that machine actually exists.",
    skills: ["ReactJS", "NodeJS", "Express", "MongoDB"],
    image: coffeeTimeImage,
    links: {
      github: "",
      preview: "https://coffeetime.link",
    },
  },
  /*
  {
    title: "AutoBook Mobile",
    description:
        "Mobile version of the AutoBook. Available on Google Play Store",
    skills: ["ReactJS", "React Native"],
    image: autoBookImage,
    links: {
      github: "https://github.com/caanmert/autobook-mobile",
      preview: "play.google.com/store/apps/details?id=com.autobookmobile",
    },
  }, */

  {
    title: "AutoBook",
    description:
        "Built for car mechanics, It allows you to keep track of your garage history. You can keep info about customer's cars, which parts are changed by you. Available on Android",
    skills: ["ReactJS", "Redux", "NodeJS", "Express", "MongoDB"],
    image: autoBookImage,
    links: {
      github: "https://github.com/caanmert/autobook-mobile",
      preview: "https://play.google.com/store/apps/details?id=com.autobookmobile",
    },
  },
  {
    title: "Todo App",
    description:
        "Simple todo app with CRUD functionality.",
    skills: ["Java", "Servlet", "JDBC", "PostgreSQL"],
    image: "image",
    links: {
      github: "https://github.com/caanmert/todoapp",
      preview: "https://github.com/caanmert/todoapp",
    },
  },
  {
    title: "Exchange App",
    description:
        "It used to convert exchanges rates",
    skills: ["ReactJS", "CSS"],
    image: "image",
    links: {
      github: "https://github.com/caanmert/exchangeapp",
      preview: "https://github.com/caanmert/exchangeapp",
    },
  },
];
