import React from "react";
import Typical from "react-typical";
import "../css/about.css";

const About = () => (
  <>
    <div className="about-section container-lg" id="about">

      <Typical
        className="about-title"
        steps={["Hello", 1000, "Hello, I'm Can", 500]}
      />

      <div className="about-description">
        I am a Software Developer based in Varna, Bulgaria.
        Currently working as Full-Stack Developer in Telespazio.
        {/*   I am last year CS Student in Technical University of Varna. */}
        <br />
      </div>
    </div>
  </>
);

export default About;
