/* eslint-disable react/prop-types */
import React from "react";
import "../css/projects.css";
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";

const Projects = (props) => {
  const { projects } = props;
  return (
    <>
      <div className="projects-container container-lg">
        {projects.map((proj) => (
          <ProjectCard
            title={proj.title}
            description={proj.description}
            skills={proj.skills}
            links={proj.links}
            image={proj.image}
            key={proj.title}
          />
        ))}
      </div>
    </>
  );
};

const ProjectCard = (props) => {
  const {
    image, title, description, skills, links,
  } = props;
  return (
    <>
      <div className="project-card">
        {/* <img className="project-image" src={image} alt="project"  */}
        <div className="project-background" />
        <div className="project-content">
          <h3>{title}</h3>
          <p dangerouslySetInnerHTML={{ __html: description }} />
          <ProjectSkills skills={skills} />
          <ProjectLinks links={links} />

        </div>
      </div>
    </>
  );
};

const ProjectSkills = (props) => {
  const { skills } = props;
  return (
    <>
      <div className="project-skills">
        {skills.map((skill) => <ProjectSkill skill={skill} key={skill} />)}
      </div>
    </>
  );
};

const ProjectSkill = (props) => {
  const { skill } = props;
  return (
    <>
      <span className="project-skill">{skill}</span>
    </>
  );
};

const ProjectLinks = (props) => {
  const { links: { github, preview } } = props;
  return (
    <div className="project-links">
      <a
        href={preview}
        rel="noreferrer"
        target="_blank"
        className="project-link"
      >
        Live
        <FaExternalLinkAlt />

      </a>
      <a
        href={github}
        rel="noreferrer"
        target="_blank"
        className="project-link secondary"
      >
        View Source
        <FaGithub />
      </a>
    </div>
  );
};

export default Projects;
