import React from "react";
import "../css/terminal.css";

const Terminal = (props) => (
  <>
    <div className="terminal-container container-lg">
      <div className="terminal">
        <div className="terminal-header">
          <div className="header-button red" />
          <div className="header-button yellow" />
          <div className="header-button green" />
        </div>
        <div className="terminal-window">
          <Statements statements={props.statements} />
        </div>
      </div>
    </div>
  </>
);

const Statements = (props) => (
  <>

    <div>
      {props.statements.map((statement, index) => <Statement statement={statement} key={index} />)}
      <div className="statement">
        <div className="input-statement">
          <span>&nbsp;</span>
        </div>
      </div>
      {/*       <div style={{
        textAlign: "end",
        padding: "10px",
        fontStyle: "italic",
        color: "gray",
        fontWeight: "bold",
      }}
      >
        Design of this terminal belongs to someone else
      </div> */}
    </div>
  </>
);

const Statement = (props) => (

  <>
    <div className="statement">
      <div className="input-statement">{props.statement.input}</div>
      <div
        className="return-statement"
        dangerouslySetInnerHTML={{ __html: props.statement.return }}
      />
    </div>
  </>
);

export default Terminal;
